import compliance from "../assets/img/compliance-dark.png";
import strategy from "../assets/img/strategy-dark.png";
import governance from "../assets/img/governance-dark.png";
import licensing from "../assets/img/licensing-dark.png";
import venture from "../assets/img/venture-dark.png"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const Services = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="service" id="services">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="service-bx wow zoomIn">
                        <h2>Our Services</h2>
                        <p>Arden Consult provides a comprehensive suite of services, including but not limited to:</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme service-slider">
                            <div className="item">
                                <img src={compliance} alt="Image" />
                                <h5>Regulatory Compliance & Advisory</h5>
                            </div>
                            <div className="item">
                                <img src={strategy} alt="Image" />
                                <h5>Business Strategy &<br /> Market Entry</h5>
                            </div>
                            <div className="item">
                                <img src={licensing} alt="Image" />
                                <h5>Licensing &<br />Regulatory Approvals</h5>
                            </div>
                            <div className="item">
                                <img src={governance} alt="Image" />
                                <h5>Corporate Governance &<br />Risk Management</h5>
                            </div>
                            <div className="item">
                                <img src={venture} alt="Image" />
                                <h5>Mergers, Acquisitions, &<br />Joint Venture</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
  )
}
